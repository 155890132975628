import styled from 'styled-components';
import { Select as ExtSelect } from 'ext/components/ui';

export const Select = styled(ExtSelect)`
  max-width: 100% !important;

  position: relative;
  box-sizing: border-box;
  font-size: var(--regular);
  min-width: 256px;

  [class$='-a11yText'], [class$='-A11yText'] {
    z-index: 9999;
    border: 0px;
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    width: 1px;
    position: absolute;
    overflow: hidden;
    padding: 0px;
    white-space: nowrap;
  }

  .react-select {
    &__control {
      -webkit-box-align: center;
      align-items: center;
      border-color: var(--select-border);
      border-radius: 6px;
      border-style: solid;
      border-width: 1px;
      box-shadow: none;
      cursor: pointer;
      display: flex;
      flex-wrap: wrap;
      -webkit-box-pack: justify;
      justify-content: space-between;
      min-height: 42px;
      position: relative;
      transition: border-color 200ms ease-in-out;
      box-sizing: border-box;
      background: var(--select-bg);
      outline: 0px !important;

      &:hover,
      &:active {
        border-color: var(--select-active);
      }
    }

    &__single-value {
      color: var(--select-text);
      grid-area: 1 / 1 / 2 / 3;
      margin-left: 2px;
      margin-right: 2px;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      box-sizing: border-box;
    }

    &__placeholder {
      color: var(--select-placeholder);
      grid-area: 1 / 1 / 2 / 3;
      margin-left: 2px;
      margin-right: 2px;
      box-sizing: border-box;
    }

    &__input {
      background: 0px center;
      opacity: 1;
      width: 100%;
      grid-area: 1 / 2 / auto / auto;
      font: inherit;
      min-width: 2px;
      border: 0px;
      margin: 0px;
      outline: 0px;
      padding: 0px;

      // override
      color: 'var(--select-text)';
    }

    &__input-container {
      margin: 2px;
      padding-bottom: 2px;
      padding-top: 2px;
      visibility: visible;
      color: var(--select-text);
      flex: 1 1 auto;
      display: inline-grid;
      grid-area: 1 / 1 / 2 / 3;
      grid-template-columns: 0px min-content;
      box-sizing: border-box;

      &:after {
        content: attr(data-value) ' ';
        visibility: hidden;
        white-space: pre;
        grid-area: 1 / 2;
        font: inherit;
        min-width: 2px;
        border: 0px;
        margin: 0px;
        outline: 0px;
        padding: 0px;
      }
    }

    &__value-container {
      align-items: center;
      display: grid;
      flex: 1;
      flex-wrap: wrap;
      padding: 2px 8px;
      -webkit-overflow-scrolling: touch;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      &--is-multi {
        -webkit-box-align: center;
        align-items: center;
        display: flex;
        flex: 1 1 0%;
        flex-wrap: wrap;
        padding: 2px 8px;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;
      }
    }

    &__indicator-separator {
      align-self: stretch;
      background-color: rgb(204, 204, 204);
      margin-bottom: 8px;
      margin-top: 8px;
      width: 1px;
      box-sizing: border-box;
      display: none;
    }

    &__indicator {
      color: var(--select-inactive);
      display: flex;
      padding: 0px;
      transition: color 150ms;
      box-sizing: border-box;
      -webkit-box-align: center;
      align-items: center;
      height: 14px;
      margin-right: 12px;
      width: 14px;

      &:hover {
        color: var(--select-inactive);
      }

      svg {
        display: inline-block;
        fill: currentColor;
        line-height: 1;
        stroke: currentColor;
        stroke-width: 0;
      }
    }

    &__indicators {
      -webkit-box-align: center;
      align-items: center;
      align-self: stretch;
      display: flex;
      flex-shrink: 0;
      box-sizing: border-box;
    }

    &__dropdown-indicator {
      transition: color 150ms;
      box-sizing: border-box;

      // override
      align-items: 'center';
      color: 'var(--select-inactive)';
      display: 'flex';
      height: '14px';
      margin-right: '12px';
      padding: '0px';
      width: '14px';

      &:hover {
        color: 'var(--select-inactive)';
      }
    }

    &__multi-value {
      border-radius: 4px;
      display: flex;
      margin: 2px;
      min-width: 0px;
      box-sizing: border-box;
      background: var(--select-option-bg);

      & > * {
        color: var(--select-text);
      }

      &__label {
        border-radius: 2px;
        font-size: 85%;
        overflow: hidden;
        padding: 3px 3px 3px 6px;
        text-overflow: ellipsis;
        white-space: nowrap;
        box-sizing: border-box;
      }

      &__remove {
        -webkit-box-align: center;
        align-items: center;
        border-radius: 2px;
        display: flex;
        padding-left: 4px;
        padding-right: 4px;
        box-sizing: border-box;
        color: var(--select-inactive);

        svg {
          display: inline-block;
          fill: currentcolor;
          line-height: 1;
          stroke: currentcolor;
          stroke-width: 0;
        }
      }
    }

    &__menu {
      top: 100%;
      background-color: var(--select-bg);
      border-radius: 6px;
      box-shadow: var(--select-menu-shadow);
      margin-bottom: 8px;
      margin-top: 6px;
      position: absolute;
      width: 100%;
      z-index: 1;
      box-sizing: border-box;
      color: var(--select-text);
      padding: 8px;

      &-list {
        max-height: 300px;
        overflow-y: auto;
        padding-bottom: 4px;
        padding-top: 4px;
        position: relative;
        -webkit-overflow-scrolling: touch;
        box-sizing: border-box;
      }
    }

    &__option {
      color: inherit;
      cursor: pointer;
      display: flex;
      font-size: var(--regular);
      padding: 8px 12px;
      width: 100%;
      user-select: none;
      -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      box-sizing: border-box;
      border-radius: 6px;
      background-color: transparent;

      &--is-focused {
        background: var(--select-option-bg);
      }

      &--is-selected {
        background: none,
        color: var(--select-option-selected);
        fontWeight: 'var(--bold)';
      }
    }

    &:active {
      background-color: rgb(178, 212, 255);
    }
  }
`;
