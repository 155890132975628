import React from 'react';
import PropTypes from 'prop-types';

import { Button, Icon, Modal } from '@appcues/sonar';
import { faXmark } from '@fortawesome/pro-solid-svg-icons/faXmark';
import { faOctagonExclamation } from '@fortawesome/pro-regular-svg-icons/faOctagonExclamation';

const ConfirmationModal = ({
  languageToDelete,
  onCancel,
  onConfirm,
  portal,
}) => (
  <Modal.Root
    open={Boolean(languageToDelete)}
    onOpenChange={onCancel}
    size="small"
    container={portal}
  >
    <Modal.Title>
      <Icon icon={faOctagonExclamation} size="large" color="#DD2270" />
      Delete {languageToDelete?.name || 'language'}?
    </Modal.Title>
    <Modal.Close aria-label="close modal" onClick={onCancel}>
      <Icon size="regular" icon={faXmark} />
    </Modal.Close>
    <Modal.Description>
      This action is irreversible. Deleting this language will remove all its
      associated translations across all experiences.
    </Modal.Description>
    <Modal.Actions>
      <Button onClick={onCancel} variant="tertiary">
        Cancel
      </Button>
      <Button onClick={onConfirm} variant="destructive">
        Delete
      </Button>
    </Modal.Actions>
  </Modal.Root>
);

ConfirmationModal.propTypes = {
  languageToDelete: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  portal: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
};

export default ConfirmationModal;
