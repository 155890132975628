import { sanitize } from 'entities/step-children';
import { updateToLocalized } from 'lib/localization';
import { MODES } from './templates';
import { mapTraits, mapStepChild } from './mappers';

export const createTemplateStepChild =
  ({ template, presentation, locales }) =>
  ({ existingStepChild }) => {
    const mappedStepChild = mapStepChild({
      existingStepChild,
      step: sanitize(template.children[0]),
      presentation,
    });

    return updateToLocalized(mappedStepChild, locales);
  };

export const createTemplateStepGroup = ({
  template,
  presentation,
  skipTraits,
  templateStepChild,
}) => ({
  ...sanitize(template),
  ...(!skipTraits && {
    traits: mapTraits({ templateTraits: template.traits, presentation }),
  }),
  children: [templateStepChild({})],
});

export const updateStepGroup = ({
  stepGroup,
  selected,
  mode,
  templateStepChild,
  template,
  presentation,
  skipTraits,
}) => {
  if (stepGroup.id !== selected.stepGroup) return stepGroup;

  const shouldUpdateTraits =
    !skipTraits && [MODES.REPLACE_CHILD, MODES.ADD_CHILD].includes(mode);
  const updatedTraits = shouldUpdateTraits
    ? mapTraits({
        currentTraits: stepGroup.traits,
        templateTraits: template.traits,
        presentation,
      })
    : stepGroup.traits;

  const updatedChildren = [
    ...stepGroup.children.map(stepChild =>
      stepChild.id === selected.stepChild && mode === MODES.REPLACE_CHILD
        ? templateStepChild({ existingStepChild: stepChild })
        : stepChild
    ),
    ...(mode === MODES.ADD_CHILD ? [templateStepChild({})] : []),
  ];

  return { ...stepGroup, traits: updatedTraits, children: updatedChildren };
};

/**
 * Determines which step should be focused after applying a template.
 *
 * This function handles three scenarios:
 * 1. When adding a new group, it focuses on the first child of the newly added group.
 * 2. When adding a new child, it focuses on the latest child of the current group.
 * 3. When replacing a child, it maintains focus on the currently selected step.
 *
 * @param {Object} experience - The current experience object containing step groups.
 * @param {string} mode - The mode of operation (e.g., ADD_GROUP, ADD_CHILD, REPLACE_CHILD).
 * @param {Object} selected - The currently selected step information.
 * @returns {Object} An object containing the id and parentId of the step to focus.
 */
export const getStepToFocus = ({ experience, mode, selected }) => {
  if (mode === MODES.ADD_GROUP) {
    const parentId = Object.keys(experience.stepGroups).at(-1);
    const id = experience.stepGroups[parentId].children[0];
    return { id, parentId };
  }

  if (mode === MODES.ADD_CHILD) {
    const parentId = selected.stepGroup;
    const id = experience.stepGroups[parentId].children.at(-1);
    return { id, parentId };
  }

  return { id: selected.stepChild, parentId: selected.stepGroup };
};
