import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Popover } from '@appcues/sonar';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import useToggle from 'ext/lib/hooks/use-toggle';

const PopoverContainer = styled.div``;

const InfoButton = styled(Button).attrs({
  iconOnly: 'true',
  variant: 'tertiary',
  as: 'div',
  children: <Icon icon={faInfoCircle} />,
})`
  && {
    svg {
      color: var(--foreground-link);
    }
  }
`;

const handleClick = link => window.open(link, '_blank');

const InfoPopover = ({ content, link }) => {
  const [isOpen, setIsOpen] = useToggle(false);

  return (
    <PopoverContainer
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();
        setIsOpen(!isOpen);
      }}
    >
      <Popover.Root trigger={<InfoButton />} onOpenChange={setIsOpen}>
        <Popover.Close>
          <Icon icon={faXmark} />
        </Popover.Close>
        <Popover.Description>{content}</Popover.Description>
        {link && (
          <Popover.Action>
            <Button
              onClick={() => handleClick(link)}
              variant="tertiary"
              type="button"
            >
              Learn more
              <Icon icon={faExternalLinkAlt} />
            </Button>
          </Popover.Action>
        )}
      </Popover.Root>
    </PopoverContainer>
  );
};

InfoPopover.propTypes = {
  content: PropTypes.node,
  link: PropTypes.string,
};

export default InfoPopover;
