import PropTypes from 'prop-types';
import { type as closeType } from './types/close';
import { type as continueType } from './types/continue';
import { type as launchExperienceType } from './types/launch-experience';
import { type as linkType } from './types/link';
import { type as requestReviewType } from './types/request-review';
import { type as requestPushType } from './types/request-push';
import { type as trackEventType } from './types/track-event';
import { type as submitFormType } from './types/submit-form';
import { type as userPropertiesType } from './types/user-properties';
import { type as noOpType } from './types/no-op';

export default PropTypes.shape({
  on: PropTypes.oneOf(['tap', 'longpress']),
  type: PropTypes.oneOf([
    closeType,
    continueType,
    launchExperienceType,
    linkType,
    requestReviewType,
    requestPushType,
    trackEventType,
    submitFormType,
    userPropertiesType,
    noOpType,
  ]),
  // eslint-disable-next-line react/forbid-prop-types
  config: PropTypes.object,
});
