import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@appcues/sonar';
import { FieldSet } from 'ext/components/ui';
import {
  requestReviewType,
  getRequestReview,
  Shape as ActionsShape,
} from 'lib/actions';
import InfoPopover from 'components/InfoPopover';
import { SwitchLabel } from './styled';

const STORE_REVIEW_LINK =
  'https://docs.appcues.com/en_US/requesting-store-review';

export default function RequestReviewOption({ actions, onChange }) {
  const requestReview = actions?.some(
    action => action.type === requestReviewType
  );

  const handleRequestReviewChange = markRequest => {
    const otherActions =
      actions?.filter(action => action.type !== requestReviewType) || [];

    onChange({
      actions: [...otherActions, ...(markRequest ? [getRequestReview()] : [])],
    });
  };

  return (
    <FieldSet>
      <Switch
        id="prompt"
        checked={requestReview}
        onCheckedChange={handleRequestReviewChange}
        fullWidth
      >
        <SwitchLabel>
          Prompt app store rating{' '}
          <InfoPopover
            content="This prompt opens at Apple's or Google's discretion and depends
                on the user's history in the app, previous ratings, and/or
                opt-outs."
            link={STORE_REVIEW_LINK}
          />
        </SwitchLabel>
      </Switch>
    </FieldSet>
  );
}

RequestReviewOption.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  onChange: PropTypes.func,
};
