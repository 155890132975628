import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Popover } from '@appcues/sonar';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';

const LEARN_MORE_LINK = 'https://docs.appcues.com/mobile-custom-components';

const QuestionIconButton = styled(Button).attrs({
  'aria-label': 'About custom components',
  iconOnly: 'true',
  variant: 'tertiary',
  as: 'div',
  children: <Icon icon={faInfoCircle} />,
})`
  position: absolute;
  top: 19px;
  right: 130px;
  z-index: var(--z-index-300);

  && {
    svg {
      color: var(--blurple);
    }
  }
`;

const handleClick = link => window.open(link, '_blank');

const VersionPopover = ({ toggleOpen, link = LEARN_MORE_LINK }) => {
  return (
    <Popover.Root trigger={<QuestionIconButton />} onOpenChange={toggleOpen}>
      <Popover.Close>
        <Icon icon={faXmark} />
      </Popover.Close>
      <Popover.Description>
        Custom components require
        <br />
        SDK versions ≥ 4.3.0
      </Popover.Description>
      <Popover.Action>
        <Button
          onClick={() => handleClick(link)}
          variant="tertiary"
          type="button"
        >
          Learn more
          <Icon icon={faExternalLinkAlt} />
        </Button>
      </Popover.Action>
    </Popover.Root>
  );
};

VersionPopover.propTypes = {
  toggleOpen: PropTypes.func,
  link: PropTypes.string,
};

export default VersionPopover;
