import styled, { css } from 'styled-components';
import { DropdownMenu as SonarDropdownMenu } from '@appcues/sonar';

const baseMenuItemStyle = css`
  align-items: center;

  &[aria-pressed='true'] {
    color: var(--foreground-button-secondary-active);
    background: var(--background-button-secondary-active);
    font-weight: var(--font-weight-bold);
  }

  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

const DropdownMenuTrigger = styled(SonarDropdownMenu.Trigger)`
  display: flex;
  align-items: center;
  border-radius: 6px;

  svg[data-icon='caret-down'] {
    height: 14px;
  }

  && {
    color: var(--color-neutral-700);
    border: none;
    background: transparent;
  }

  &:hover {
    color: var(--foreground-button-secondary-default);
    background: var(--background-button-secondary-hover);
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }
`;

const DropdownMenuTriggerLabel = styled.span`
  font-weight: var(--font-weight-bold);
  margin: 0px var(--spacing-small);
`;

const DropdownMenuContent = styled(SonarDropdownMenu.Content)`
  z-index: 1;
`;

const DropdownMenuItem = styled(SonarDropdownMenu.Item)`
  ${baseMenuItemStyle}
`;

const DropdownMenuSubTrigger = styled(SonarDropdownMenu.SubTrigger)`
  ${baseMenuItemStyle}
`;

const DropdownMenuRightSlot = styled(SonarDropdownMenu.RightSlot)`
  span {
    padding: 0px;
  }
`;

const DropdownMenuSubContent = styled(SonarDropdownMenu.SubContent)`
  z-index: 2;
`;

const DropdownScrollableList = styled.div`
  max-height: 350px;
  overflow-y: auto;
`;

export const DropdownMenu = {
  ...SonarDropdownMenu,
  Trigger: DropdownMenuTrigger,
  TriggerLabel: DropdownMenuTriggerLabel,
  Content: DropdownMenuContent,
  Item: DropdownMenuItem,
  SubTrigger: DropdownMenuSubTrigger,
  RightSlot: DropdownMenuRightSlot,
  SubContent: DropdownMenuSubContent,
  ScrollableList: DropdownScrollableList,
};

export const InputFile = styled.input`
  display: none;
`;

export const Loading = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 10;
`;

export const Translating = styled.p`
  font-size: var(--font-size-x-large);
  margin-left: var(--spacing-regular);
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;
