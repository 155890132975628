import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { Button, Flyout, Icon } from '@appcues/sonar';
import {
  selectUserProperties,
  userPropertiesShape,
} from 'ext/entities/user-properties';
import {
  create as createLocale,
  remove as removeLocale,
  update as updateLocale,
  Shape as LocalesShape,
} from 'ext/entities/locales';
import {
  getLanguageFromCode,
  LOCALIZATION_BANNERS_LINK,
} from 'ext/lib/localization';
import { LocalesList } from './LocalesList';
import { AddLocale } from './AddLocale';

export const LocalesFlyout = ({
  locales = [],
  userProperties,
  status,
  open,
  portal,
  onClose,
  overrideSelect,
  onCreateLocale,
  onRemoveLocale,
  onUpdateLocale,
  learnMoreLink = LOCALIZATION_BANNERS_LINK,
}) => {
  const [isAddingLanguage, setIsAddingLanguage] = useState(false);
  const [editingLanguage, setEditingLanguage] = useState();
  const [openFlyout, setOpenFlyout] = useState(null);

  const handleFlyoutClose = () => {
    setIsAddingLanguage(false);
    setEditingLanguage();
    setOpenFlyout(null);
    onClose();
  };

  const localesList = locales.map(({ id, name, conditions }) => {
    const { property, value } = conditions?.and[0].properties ?? {};

    const propertyName = userProperties?.find(
      userProperty => userProperty.value === property
    )?.name;

    const targetCriteriaNames =
      value
        ?.split('\n')
        ?.map(targetCriteriaCode => getLanguageFromCode(targetCriteriaCode)) ??
      [];

    return {
      id,
      name,
      property: propertyName,
      targetCriteria: targetCriteriaNames,
    };
  });

  const handleRemoveLocale = id => {
    onRemoveLocale(id);
  };

  return (
    <Flyout.Root
      open={open}
      modal={false}
      onOpenChange={() =>
        openFlyout === null ? setOpenFlyout(true) : handleFlyoutClose()
      }
      size="large"
      container={portal}
    >
      {isAddingLanguage || editingLanguage ? (
        <AddLocale
          userProperties={userProperties}
          setIsAddingLanguage={setIsAddingLanguage}
          isEditingLanguage={editingLanguage}
          setEditingLanguage={setEditingLanguage}
          onCreateLocale={onCreateLocale}
          onUpdateLocale={onUpdateLocale}
          locales={localesList}
          overrideSelect={overrideSelect}
        />
      ) : (
        <>
          <Flyout.Header>
            <Flyout.Title>Localization</Flyout.Title>
            <Flyout.Description>
              Tell us which languages you plan to support, as well as the
              criteria we should use when targeting those speakers.
            </Flyout.Description>
          </Flyout.Header>
          <Flyout.Content>
            <LocalesList
              portal={portal}
              locales={localesList}
              status={status}
              setEditingLanguage={setEditingLanguage}
              onRemoveLocale={handleRemoveLocale}
            />
          </Flyout.Content>
          <Flyout.Footer>
            <Flyout.Actions>
              <Button
                onClick={() => window.open(learnMoreLink, '_blank')}
                variant="tertiary"
              >
                Learn more
                <Icon icon={faExternalLinkAlt} />
              </Button>
              <Button onClick={setIsAddingLanguage} variant="primary">
                <Icon icon={faPlus} />
                Add language
              </Button>
            </Flyout.Actions>
          </Flyout.Footer>
        </>
      )}
    </Flyout.Root>
  );
};

LocalesFlyout.propTypes = {
  learnMoreLink: PropTypes.string,
  locales: PropTypes.arrayOf(LocalesShape),
  userProperties: userPropertiesShape,
  status: PropTypes.string,
  open: PropTypes.bool,
  portal: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  onClose: PropTypes.func,
  onCreateLocale: PropTypes.func,
  onRemoveLocale: PropTypes.func,
  onUpdateLocale: PropTypes.func,
  overrideSelect: PropTypes.bool,
};

const mapStateToProps = state => ({
  userProperties: selectUserProperties(state),
});

const mapDispatchToProps = {
  onCreateLocale: createLocale,
  onRemoveLocale: removeLocale,
  onUpdateLocale: updateLocale,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocalesFlyout);
