import styled from 'styled-components';
import {
  Button as SonarButton,
  Form as SonarForm,
  FormField as SonarFormField,
  InlineMessage as SonarInlineMessage,
  Text,
} from '@appcues/sonar';
import { Select as ExtSelect } from 'ext/components/ui';

export const Select = styled(ExtSelect)`
  max-width: 100% !important;
`;

export const Form = styled(SonarForm)`
  flex-direction: column;
  height: 100%;

  ${SonarFormField} {
    flex: 1;
    padding-bottom: var(--spacing-regular);

    ${SonarInlineMessage} {
      margin-top: var(--spacing-small);
      position: relative;
    }
  }
`;

export const TargetCriteria = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-small);
`;

export const ActionButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: var(--spacing-x-small);
  width: 72px;

  ${SonarButton} {
    min-width: 20px;
    width: 20px;
    height: 20px;
    padding: 16px;

    &[data-action='confirm'],
    &[data-action='confirm']:hover {
      color: var(--foreground-success);
    }

    &[data-action='cancel'],
    &[data-action='cancel']:hover {
      color: var(--foreground-error);
    }
  }
`;

export const Loading = styled.div`
  z-index: 2;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  gap: var(--spacing-small);
`;

export const LanguageList = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-regular);
`;

export const LanguageCard = styled.div`
  border-radius: var(--radius-small);
  border: 1px solid var(--border-default);
  background: var(--background-level-2);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-small);
  padding: var(--spacing-regular);
`;

export const LanguageCardHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const CardText = styled(Text)`
  color: var(--foreground-secondary);
  display: inline-block;

  b {
    color: var(--foreground-primary);
  }
`;
