/**
 *  Default locale option
 *
 * @constant
 */
export const DEFAULT_LOCALE = {
  id: 'default-locale',
  name: 'Default',
};

export const getLocalizedBlockId = ({ blockId, localeId }) => {
  const validLocaleId = localeId !== DEFAULT_LOCALE.id;
  return validLocaleId ? `${blockId}/${localeId}` : blockId;
};
