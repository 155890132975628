import styled from 'styled-components';
import { FieldSet, Select, Input, FontIcon } from 'ext/components/ui';
import { Switch } from '@appcues/sonar';

export const GroupedFieldSet = styled(FieldSet)`
  display: flex;
  gap: 16px;
`;

export const CloseIcon = styled(FontIcon)`
  cursor: pointer;
  color: var(--sharkbait-ooh-la-la);
  position: absolute;
  right: 8px;
  top: 12px;

  && {
    width: 10px;
  }
`;

export const GroupedField = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  ${Input} {
    padding-right: 18px;
  }

  ${Select} {
    div[id$='placeholder'] {
      font-size: 13px;
      white-space: nowrap;
    }
  }

  ${Select},
  ${Input} {
    min-width: 100%;
  }
`;

export const SwitchLabel = styled(Switch.Label)`
  display: flex;
  align-items: center;
  gap: var(--spacing-x-small);
`;
