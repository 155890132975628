import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons/faTrashCan';

import { Badge, Button, Icon, Spinner } from '@appcues/sonar';
import { LocalesTableShape } from 'ext/entities/locales';

import ConfirmationModal from './components/ConfirmationModal';
import {
  CardText,
  TargetCriteria,
  ActionButtons,
  LanguageCard,
  LanguageCardHeader,
  LanguageList,
  Loading,
} from './styled';

export const LOADING = 'loading';
export const LOADED = 'loaded';
export const ERROR = 'error';

export const LocalesList = ({
  locales = [],
  status,
  portal,
  setEditingLanguage,
  onRemoveLocale,
}) => {
  const [languageToDelete, setLanguageToDelete] = useState(null);

  return (
    <div>
      <LanguageList>
        <LanguageCard>
          <LanguageCardHeader>
            <CardText size="regular">
              <b>Default</b>
            </CardText>
          </LanguageCardHeader>
          <CardText>
            Language used for any language without a translation.
          </CardText>
        </LanguageCard>
        {locales?.map(locale => {
          const { id, name, property, targetCriteria } = locale;
          return (
            <LanguageCard key={id}>
              <LanguageCardHeader>
                <CardText size="regular">
                  <b>{name}</b>
                </CardText>
                <ActionButtons>
                  <Button
                    aria-label="Delete language"
                    onClick={() => setLanguageToDelete(locale)}
                    variant="tertiary"
                  >
                    <Icon icon={faTrashCan} />
                  </Button>
                  <Button
                    aria-label="Edit language"
                    onClick={() => setEditingLanguage(locale)}
                    variant="tertiary"
                  >
                    <Icon icon={faPenToSquare} />
                  </Button>
                </ActionButtons>
              </LanguageCardHeader>
              <CardText>
                <b>Property:</b> {property}
              </CardText>
              <TargetCriteria>
                {targetCriteria.map(targetCriteriaCode => (
                  <Badge key={targetCriteriaCode}>{targetCriteriaCode}</Badge>
                ))}
              </TargetCriteria>
            </LanguageCard>
          );
        })}
        {status === LOADING && (
          <Loading>
            <Spinner />
          </Loading>
        )}
      </LanguageList>

      <ConfirmationModal
        portal={portal}
        languageToDelete={languageToDelete}
        onCancel={() => setLanguageToDelete(null)}
        onConfirm={() => {
          onRemoveLocale(languageToDelete?.id);
          setLanguageToDelete(null);
        }}
      />
    </div>
  );
};

LocalesList.propTypes = {
  locales: LocalesTableShape,
  portal: PropTypes.oneOfType([PropTypes.element, PropTypes.object]),
  status: PropTypes.oneOf([LOADING, LOADED, ERROR]),
  setEditingLanguage: PropTypes.func,
  onRemoveLocale: PropTypes.func,
};
