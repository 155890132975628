import { v4 as uuid } from 'uuid';
import {
  TEXT,
  IMAGE,
  HERO,
  BUTTON,
  VIDEO,
  EMOJI,
  ROW,
  STACK,
  IMAGE_WITH_TEXT,
  TEXT_INPUT,
  SINGLE_SELECT,
  MULTI_SELECT,
  RATING,
  RATING_EMOJI,
  CUSTOM_COMPONENT,
} from 'lib/block';
import { continueType, submitFormType } from 'lib/actions';
import { updateToLocalized } from 'lib/localization';
import {
  defaultActionsTemplate,
  defaultActionsWithSubmitTemplate,
} from './actions';
import {
  textTemplate,
  imageTemplate,
  heroTemplate,
  buttonTemplate,
  videoTemplate,
  emojiTemplate,
  imageWithTextTemplate,
  customComponentTemplate,
} from './Content';
import {
  textInputTemplate,
  singleSelectTemplate,
  multiSelectTemplate,
  ratingNumberTemplate,
  ratingEmojiTemplate,
} from './Survey';

export const generateRow = items => ({
  type: STACK,
  id: uuid(),
  orientation: 'horizontal',
  distribution: 'equal',
  role: ROW,
  items,
});

const generateElementTemplate = ({
  template,
  customComponentModel,
  blockId,
  elementId,
  newRow = false,
  content = {},
  style = {},
  locales = [],
}) => {
  const elementGenerators = {
    [TEXT]: textTemplate,
    [IMAGE]: imageTemplate,
    [HERO]: heroTemplate,
    [BUTTON]: buttonTemplate,
    [VIDEO]: videoTemplate,
    [EMOJI]: emojiTemplate,
    [IMAGE_WITH_TEXT]: imageWithTextTemplate,
    [TEXT_INPUT]: textInputTemplate,
    [SINGLE_SELECT]: singleSelectTemplate,
    [MULTI_SELECT]: multiSelectTemplate,
    [RATING]: ratingNumberTemplate,
    [RATING_EMOJI]: ratingEmojiTemplate,
    [CUSTOM_COMPONENT]: customComponentTemplate,
    [continueType]: defaultActionsTemplate,
    [submitFormType]: defaultActionsWithSubmitTemplate,
  };

  let element =
    elementGenerators[template]?.({
      blockId,
      elementId,
      content,
      style,
      customComponentModel,
    }) ?? null;

  if (!element) {
    return null;
  }

  element = updateToLocalized(element, locales);

  if (newRow) {
    element = generateRow([element]);
  }

  return element;
};

export default generateElementTemplate;
