import React, { useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { faWandSparkles } from '@fortawesome/free-solid-svg-icons/faWandSparkles';
import { Accordion, FontIcon, Input, FieldSet, Label } from 'ext/components/ui';
import { Badge, Button, Icon } from '@appcues/sonar';
import { Shape as BlockContentShape } from 'entities/block';
import { create as createApiClient } from 'lib/api-client';
import {
  Controls,
  useAccordionClick,
  GroupedFieldSet,
  GroupedField,
} from 'components/SideBarSettings/Shared';
import { Error } from 'components/Form';
import InfoPopover from 'components/InfoPopover';
import { SmartButton } from './SmartButton';

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const AI_LINK =
  'https://docs.appcues.com/en_US/mobile-building-experiences/styling-mobile-modals';

const smartTranslations = [
  {
    text: 'Fix Spelling',
    prompt: 'fix spelling',
  },
  {
    text: 'Expand Text',
    prompt: 'expand on the text to provide more detail',
  },
  {
    text: 'Summarize Text',
    prompt: 'summarize text',
  },
  {
    text: 'Rephrase Text',
    prompt: 'rephrase text',
  },
  {
    text: 'Make it Friendly',
    prompt: 'make it friendly',
  },
  {
    text: 'Make it Formal',
    prompt: 'make it formal',
  },
];

export function SmartText({ content, auth, onChange }) {
  const trackAccordion = useAccordionClick();

  const [isTranslatingPrompt, setIsTranslatingPrompt] = useState('');
  const [customPrompt, setCustomPrompt] = useState('');
  const [error, setError] = useState();

  const handleSmartText = async prompt => {
    setIsTranslatingPrompt(prompt);
    const { id, text } = content ?? {};
    const { generateSmartText } = createApiClient(auth);
    let smartText = text;
    setError('');

    try {
      const response = await generateSmartText(prompt, text);
      smartText = response.text;
    } catch {
      setError('Something went wrong. Please try again.');
    }

    setIsTranslatingPrompt('');

    const updatedText = { text: smartText };
    if (typeof smartText === 'string' && smartText !== text) {
      onChange({
        blockId: id,
        contentChunk: { ...updatedText, spans: [updatedText] },
      });
    }
  };

  return (
    <Accordion.Item value="smart-text">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Text', 'Smart Text')}>
          <AccordionTitle>
            <Icon icon={faWandSparkles} />
            Appcues Assistant
            <Badge variant="info">Appcues AI</Badge>
            <InfoPopover
              content={
                <>
                  To learn more about our AI <br />
                  Assistant feature, please visit <br />
                  our documentation.
                </>
              }
              link={AI_LINK}
            />
          </AccordionTitle>
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Grid>
              {smartTranslations.map(({ text, prompt }) => (
                <SmartButton
                  key={text}
                  text={text}
                  prompt={prompt}
                  loading={isTranslatingPrompt === prompt}
                  handleSmartText={handleSmartText}
                />
              ))}
            </Grid>
          </FieldSet>

          <FieldSet>
            <Label htmlFor="custom-prompt">Custom Prompt</Label>
            <GroupedFieldSet id="custom-prompt">
              <GroupedField>
                <Input
                  onChange={e => setCustomPrompt(e.target.value)}
                  placeholder="Enter custom prompt"
                />
              </GroupedField>
              <GroupedField>
                <Button
                  loading={
                    customPrompt !== '' && isTranslatingPrompt === customPrompt
                  }
                  disabled={customPrompt === ''}
                  onClick={() => handleSmartText(customPrompt)}
                >
                  Try it out!
                </Button>
              </GroupedField>
            </GroupedFieldSet>
          </FieldSet>

          {error && <Error>{error}</Error>}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

SmartText.propTypes = {
  content: BlockContentShape,
  auth: PropTypes.shape({
    accountId: PropTypes.string,
    token: PropTypes.string,
  }),
  onChange: PropTypes.func,
};

const mapStateToProps = state => ({
  auth: state.account,
});

export default connect(mapStateToProps)(SmartText);
