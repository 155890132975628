import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from '@appcues/sonar';
import { FieldSet } from 'ext/components/ui';
import {
  requestPushType,
  getRequestPush,
  Shape as ActionsShape,
} from 'lib/actions';
import InfoPopover from 'components/InfoPopover';
import { SwitchLabel } from './styled';

export default function RequestPushOption({ actions, onChange }) {
  const requestPush = actions?.some(action => action.type === requestPushType);

  const handleRequestPushChange = markRequest => {
    const otherActions =
      actions?.filter(action => action.type !== requestPushType) || [];

    onChange({
      actions: [...otherActions, ...(markRequest ? [getRequestPush()] : [])],
    });
  };

  return (
    <FieldSet>
      <Switch
        id="prompt-push"
        checked={requestPush}
        onCheckedChange={handleRequestPushChange}
        fullWidth
      >
        <SwitchLabel htmlFor="prompt-push">
          Prompt push permission{' '}
          <InfoPopover content="This prompt will only open once per app install." />
        </SwitchLabel>
      </Switch>
    </FieldSet>
  );
}

RequestPushOption.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  onChange: PropTypes.func,
};
