import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { update, selectStepChild, ContentShape } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import {
  continueType,
  requestReviewType,
  Shape as ActionsShape,
} from 'lib/actions';
import { hasSurveyBlockType } from 'lib/block';
import {
  Actions,
  actionContexts,
  actionsMapper,
} from 'components/SideBarSettings/Shared';

export function ContentActions({
  blockId,
  selectedStepChild,
  actions,
  context,
  content,
  onUpdate,
}) {
  const prevActions = actions?.[blockId] ?? [];
  const hasSurveyBlock = hasSurveyBlockType(content);

  const handleActionsUpdate = newActions => {
    const continuePrevAction = prevActions.find(
      ({ type }) => type === continueType
    );

    const unrelatedActionsToSurvey = new Set([requestReviewType]);

    const actionsChanged = newActions?.actions
      ?.filter(({ type }) => !unrelatedActionsToSurvey.has(type))
      .some(({ type, config }) =>
        type === continueType && continuePrevAction
          ? continuePrevAction.config !== config
          : !prevActions.map(prevAction => prevAction.type).includes(type)
      );

    onUpdate(selectedStepChild, {
      actions: {
        ...actions,
        [blockId]: actionsMapper({
          prevActions,
          newActions,
          handleSubmitForm: hasSurveyBlock && actionsChanged,
        }),
      },
    });
  };

  return (
    <Actions
      actions={prevActions}
      context={context}
      hasSurveyBlock={hasSurveyBlock}
      onActionsChange={handleActionsUpdate}
    />
  );
}

ContentActions.propTypes = {
  blockId: PropTypes.string,
  selectedStepChild: PropTypes.string,
  actions: ActionsShape,
  context: PropTypes.oneOf(actionContexts),
  content: ContentShape,
  onUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: selectedStepChild } = selectSelected(state) ?? {};
  const { actions, content } = selectStepChild(state, selectedStepChild) ?? {};

  return {
    selectedStepChild,
    actions,
    content,
  };
};

const mapDispatchToProps = {
  onUpdate: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentActions);
