import React, { useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { array, object, string } from 'yup';

import {
  Flyout,
  FormField,
  Label,
  Input,
  InlineMessage,
  Button,
} from '@appcues/sonar';
import { userPropertiesShape } from 'ext/entities/user-properties';
import { LANGUAGES } from 'ext/lib/localization';

import { Form } from './styled';
import { Select } from './CustomSelect';

const DEFAULT_PROPERTY = {
  value: '_lastBrowserLanguage',
  label: 'Last Browser Language',
};

const LANGUAGES_OPTIONS = LANGUAGES.map(({ id, label }) => ({
  value: id,
  label,
}));

const handleTargetCriteriaOptions = (userProperties, userPropertyValue) => {
  const userProperty = userProperties?.find(
    ({ value }) => value === userPropertyValue
  );

  const userPropertyOptions =
    userProperty?.options?.map(({ name }) => ({ value: name, label: name })) ??
    [];

  return userPropertyValue === DEFAULT_PROPERTY.value
    ? LANGUAGES_OPTIONS
    : userPropertyOptions;
};

export const AddLocale = ({
  overrideSelect = false,
  userProperties,
  setIsAddingLanguage,
  isEditingLanguage,
  setEditingLanguage,
  onCreateLocale,
  onUpdateLocale,
  locales = [],
}) => {
  const targetCriteriaOptions = useRef([]);

  const propertyOptions = useMemo(
    () =>
      userProperties?.map(({ value, name }) => ({
        value,
        label: name,
      })),
    [userProperties]
  );

  const updateTargetCriteriaOptions = property => {
    const targetCriteriaEditingOptions = handleTargetCriteriaOptions(
      userProperties,
      property.value
    );
    targetCriteriaOptions.current = targetCriteriaEditingOptions;
    return targetCriteriaEditingOptions;
  };

  const getInitialValues = () => {
    if (!isEditingLanguage) {
      updateTargetCriteriaOptions(DEFAULT_PROPERTY);

      return {
        languageName: '',
        property: DEFAULT_PROPERTY,
        targetCriteria: [],
      };
    }

    const property =
      propertyOptions?.find(
        ({ label }) => label === isEditingLanguage.property
      ) || DEFAULT_PROPERTY;

    const targetCriteriaEditingOptions = updateTargetCriteriaOptions(property);

    const targetCriteria = targetCriteriaEditingOptions.filter(({ label }) =>
      isEditingLanguage.targetCriteria.includes(label)
    );

    return {
      languageName: isEditingLanguage?.name || '',
      property,
      targetCriteria,
    };
  };

  const onCancelClick = () => {
    setIsAddingLanguage(false);

    if (isEditingLanguage) {
      setEditingLanguage();
    }
  };

  const LanguageSchema = object().shape({
    languageName: string()
      .required('This field is required.')
      .test('is-duplicate', 'Language already exists!', name => {
        if (isEditingLanguage) return true;

        return !locales?.some(
          ({ name: localeName }) =>
            localeName?.toLowerCase() === name?.toLowerCase()
        );
      }),
    targetCriteria: array().min(1, 'This field is required.'),
  });

  const onSubmit = values => {
    const { languageName: name, property, targetCriteria: criteria } = values;

    const locale = {
      name,
      conditions: {
        and: [
          {
            properties: {
              property: property.value,
              operator: 'in',
              value: criteria.map(item => item.value).join('\n'),
            },
          },
        ],
      },
    };

    setIsAddingLanguage(false);

    if (isEditingLanguage) {
      onUpdateLocale(isEditingLanguage.id, locale);
      setEditingLanguage();
      return;
    }

    onCreateLocale(locale);
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      onSubmit={onSubmit}
      validationSchema={LanguageSchema}
    >
      {({
        handleBlur,
        handleChange,
        values,
        handleSubmit,
        errors,
        dirty,
        isValid,
        setFieldValue,
        setTouched,
        touched,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Flyout.Header>
            <Flyout.Title>Edit Language</Flyout.Title>
          </Flyout.Header>
          <Flyout.Content>
            <FormField>
              <Label htmlFor="languageName">Language name</Label>
              <Input
                aria-label="language name"
                id="languageName"
                name="languageName"
                placeholder="e.g. English"
                value={values.languageName}
                error={errors.languageName && touched.languageName}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {errors.languageName && touched.languageName && (
                <InlineMessage variant="error">
                  {errors.languageName}
                </InlineMessage>
              )}
            </FormField>
            <FormField>
              <Label htmlFor="property">Property</Label>
              <Select
                classNamePrefix={overrideSelect && 'react-select'}
                id="property"
                name="property"
                value={values.property}
                options={propertyOptions}
                onChange={prop => {
                  if (!prop) return;
                  setFieldValue('property', prop);
                  setFieldValue('targetCriteria', []);
                  updateTargetCriteriaOptions(prop);
                }}
              />
            </FormField>
            <FormField>
              <Label htmlFor="targetCriteria">Targeting Criteria</Label>
              <Select
                classNamePrefix={overrideSelect && 'react-select'}
                id="targetCriteria"
                name="targetCriteria"
                placeholder="Select target criteria"
                value={values.targetCriteria}
                onChange={targetValues => {
                  setTouched({ targetCriteria: true });
                  setFieldValue('targetCriteria', targetValues);
                }}
                options={targetCriteriaOptions.current}
                isClearable
                isMulti
              />
              {errors.targetCriteria && touched.targetCriteria && (
                <InlineMessage variant="error">
                  {errors.targetCriteria}
                </InlineMessage>
              )}
            </FormField>
          </Flyout.Content>
          <Flyout.Footer>
            <Flyout.Actions>
              <Button onClick={onCancelClick} variant="secondary">
                Back
              </Button>
              <Button
                disabled={!isValid || !dirty}
                type="submit"
                variant="primary"
              >
                Save
              </Button>
            </Flyout.Actions>
          </Flyout.Footer>
        </Form>
      )}
    </Formik>
  );
};

AddLocale.propTypes = {
  overrideSelect: PropTypes.bool,
  userProperties: userPropertiesShape,
  setIsAddingLanguage: PropTypes.func,
  isEditingLanguage: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    property: PropTypes.string,
    targetCriteria: PropTypes.arrayOf(PropTypes.string),
  }),
  setEditingLanguage: PropTypes.func,
  onCreateLocale: PropTypes.func,
  onUpdateLocale: PropTypes.func,
  locales: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      property: PropTypes.string,
      targetCriteria: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};
