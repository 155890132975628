/**
 *  Default locale option
 *
 * @constant
 */
export const DEFAULT_LOCALE = {
  id: 'default-locale',
  name: 'Default',
};

/**
 *  Localization banners link to the documentation
 *
 * @constant
 */
export const LOCALIZATION_BANNERS_LINK =
  'https://docs.appcues.com/user-experiences-customization/localize-banners';
