/* eslint-disable no-use-before-define */
import { v4 as uuid } from 'uuid';
import { BLOCK, VIDEO, LOCALIZED } from 'lib/block';

/**
 * Wrap a content block with a localized block,
 * if the block is already localized only add the
 * new locales to the variations structure
 *
 * @param {object} block - block to be wrapped by localized
 * @param {array<string>} locales - locales ids to be added in the block element variations
 * @return {object} - block wrapped with localized that can have variations
 */
export function wrapWithLocalizedBlock(block, locales = []) {
  const isLocalized = block.blockType === LOCALIZED;

  const variations = locales.reduce((acc, locale) => {
    acc[locale] = isLocalized ? block.content : block;
    return acc;
  }, {});

  if (isLocalized) {
    return {
      ...block,
      variations: {
        ...block.variations,
        ...variations,
      },
    };
  }

  return {
    type: BLOCK,
    blockType: LOCALIZED,
    id: uuid(),
    content: block,
    variations,
  };
}

const handlers = {
  text: wrapWithLocalizedBlock,
  image: wrapWithLocalizedBlock,
  embed: wrapWithLocalizedBlock,
  customComponent: wrapWithLocalizedBlock,
  textInput: handleSurveyOptions,
  optionSelect: handleSurveyOptions,
};

function handleSurveyOptions(node, locales) {
  const newNode = { ...node };

  ['label', 'errorLabel', 'placeholder'].forEach(prop => {
    if (newNode[prop]) {
      newNode[prop] = wrapWithLocalizedBlock(newNode[prop], locales);
    }
  });

  if (newNode.options) {
    newNode.options = newNode.options.map(option =>
      updateToLocalized(option, locales)
    );
  }

  return newNode;
}

export function updateToLocalized(node, locales) {
  if (locales?.length === 0) return node;

  const { type, blockType, content, embed, selectedContent, items } = node;

  if (blockType === LOCALIZED) {
    return wrapWithLocalizedBlock(node, locales);
  }

  if (handlers[type]) {
    if (type === VIDEO && !embed) return node;
    return handlers[type](node, locales);
  }

  if (items) {
    return {
      ...node,
      items: node.items.map(item => updateToLocalized(item, locales)),
    };
  }

  if (content && selectedContent) {
    return {
      ...node,
      content: updateToLocalized(node.content, locales),
      selectedContent: updateToLocalized(node.selectedContent, locales),
    };
  }

  if (content) {
    return {
      ...node,
      content: updateToLocalized(node.content, locales),
    };
  }

  return node;
}

/**
 * Wrap a the experience with localized blocks
 *
 * @param {object} experienceJSON - canonical experience that will be wrapped with localized blocks
 * @return {object} - experience with localized blocks
 */
export function wrapBlocksWithLocalized(experienceJSON, locales = []) {
  const newExperienceJSON = {
    ...experienceJSON,
    containsLocalizedBlocks: true,
  };

  newExperienceJSON.steps = newExperienceJSON.steps.map(stepGroup => {
    return {
      ...stepGroup,
      children: stepGroup.children.map(stepChild => {
        return {
          ...stepChild,
          content: updateToLocalized(stepChild.content, locales),
        };
      }),
    };
  });

  return newExperienceJSON;
}
