import { LANGUAGES } from './languages';

/**
 * Get label related to a language code provide
 *
 * @param {array} languages - Array of language options with id and label
 * @return {string} Language label if found, otherwise the code
 */
export const getLanguageFromCode = code =>
  LANGUAGES.find(({ id }) => id === code)?.label || code;

/**
 * Get unique locale variations list from a content object
 *
 * @param {object} content - Content object from a step child
 * @return {array} List of founded locales ids in variations
 */
export function getLocaleVariations(content) {
  if (!content || typeof content !== 'object') return [];

  return [
    ...new Set(
      Object.keys(content).flatMap(key => {
        if (key === 'variations') {
          return Object.keys(content[key]);
        }

        return getLocaleVariations(content[key]);
      })
    ),
  ];
}
