import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocalizedBlockId } from 'lib/localization';
import { focus } from 'entities/block';
import { selectSelected } from 'entities/selected';
import { selectActiveLocale } from 'entities/locales';
import { updateSlate, selectSlate, SlateEditorShape } from 'entities/slate';
import {
  selectStepChild,
  update,
  ContentShape,
  StyleShape,
} from 'entities/step-children';
import {
  BUTTON,
  EMOJI,
  IMAGE_WITH_TEXT,
  LABEL,
  COMPOSED_BLOCKS,
  getParentBlock,
  updateElement,
} from 'lib/block';
import { THEMES } from 'lib/user-preferences';
import { Text, Emoji } from 'components/Editor/Primitives';

const DEFAULT_LABEL = {
  [BUTTON]: 'Next',
  [LABEL]: 'Question label',
};

const TextContainer = ({
  id,
  text,
  spans,
  label,
  style,
  readOnly,
  theme,
  stepId,
  content,
  selectedBlock,
  localeId,
  slateEditor,
  onClick,
  onUpdate,
  onSlateUpdate,
}) => {
  const { id: parentId, blockType } = getParentBlock(content, id) ?? {};
  const isSelected = selectedBlock === parentId;
  const isComposedBlock = COMPOSED_BLOCKS.includes(blockType);
  const isInnerBlockSelected = isComposedBlock && selectedBlock === id;

  const fillSpace = blockType === IMAGE_WITH_TEXT;

  const handleUpdate = updatedValue => {
    const localizedId = getLocalizedBlockId({ blockId: id, localeId });

    const updatedContent = updateElement({
      content,
      blockId: localizedId,
      contentChunk: updatedValue,
    });

    onUpdate(stepId, updatedContent);
  };

  const handleClick = event => {
    /**
     * If the text element is inside a composed block we should select it,
     * if the inner text block is already selected we should't select the outer block.
     * We should be aware that in other cases, we don't want to prevent stop propagation,
     * e.g. for simple text blocks we want to edit the text and select the block container.
     */
    if (isInnerBlockSelected) {
      event.stopPropagation();
      return;
    }

    if (isComposedBlock && selectedBlock !== id) {
      event.stopPropagation();
      onClick(id);
    }
  };

  const handleChange = ({ slateText, slateSpans }) => {
    if (slateText !== text) {
      const updatedText = {
        text: slateText,
        spans: slateSpans,
      };

      handleUpdate(updatedText);
    }
  };

  const handleBlur = ({ slateText }) => {
    const isEmptyText = slateText.trim().length === 0;

    // If the text value is empty, we revert to default text
    if (isEmptyText) {
      // If the current block is a label or acts like one (button)
      // we need to ensure a fallback text value
      const blockLabel = label
        ? LABEL
        : blockType === BUTTON
        ? BUTTON
        : undefined;

      const defaultText = DEFAULT_LABEL[blockLabel] || '';

      slateEditor.insertText(defaultText);

      const updatedText = {
        text: defaultText,
        spans: null,
      };

      handleUpdate(updatedText);
    }
  };

  return blockType === EMOJI ? (
    <Emoji id={id} text={text} style={style} onUpdate={handleUpdate} />
  ) : (
    <Text
      id={id}
      stepId={stepId}
      text={text}
      spans={spans}
      style={style}
      fillSpace={fillSpace}
      readOnly={readOnly}
      label={label}
      isSelected={isSelected}
      isInnerSelected={isInnerBlockSelected}
      isComposedBlock={isComposedBlock}
      theme={theme}
      onBlur={handleBlur}
      onChange={handleChange}
      onClick={handleClick}
      onSlateUpdate={onSlateUpdate}
    />
  );
};

TextContainer.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  spans: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      style: StyleShape,
    })
  ),
  label: PropTypes.shape({
    errorColor: PropTypes.string,
    required: PropTypes.bool,
  }),
  style: StyleShape,
  readOnly: PropTypes.bool,
  theme: PropTypes.oneOf(THEMES),
  stepId: PropTypes.string,
  content: ContentShape,
  selectedBlock: PropTypes.string,
  localeId: PropTypes.string,
  slateEditor: SlateEditorShape,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
  onSlateUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const { stepChild: stepId, block: selectedBlock } =
    selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};
  const slateEditor = selectSlate(state).editor ?? {};
  const { id: localeId } = selectActiveLocale(state) ?? {};

  return { stepId, content, selectedBlock, localeId, slateEditor };
};

const mapDispatchToProps = {
  onClick: focus,
  onUpdate: update,
  onSlateUpdate: updateSlate,
};

export default connect(mapStateToProps, mapDispatchToProps)(TextContainer);
