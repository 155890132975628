import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocalizedBlockId } from 'lib/localization';
import { update, ContentShape } from 'entities/step-children';
import { Shape as BlockShape } from 'entities/block';
import { selectActiveLocale } from 'entities/locales';
import { CONTENT, SURVEY, BLOCK_CATEGORIES, updateElement } from 'lib/block';
import BlockScheme from 'components/BlockScheme';
import ContentSettings from 'components/SideBarSettings/Content';
import SurveySettings from 'components/SideBarSettings/Survey';

export function BlockSettings({
  selectedStepChild,
  blockSettings,
  blockSettingsWithVariations,
  content,
  localeId,
  onUpdate,
}) {
  const { blockCategory, ...blockProperties } = blockSettings;
  const {
    blockCategory: originalBlockCategory,
    ...blockPropertiesWithVariations
  } = blockSettingsWithVariations;

  const handleUpdate = ({ blockId, contentChunk, useOriginalId = false }) => {
    const localizedId = useOriginalId
      ? blockId
      : getLocalizedBlockId({ blockId, localeId });

    const updatedContent = updateElement({
      content,
      blockId: localizedId,
      contentChunk,
    });

    onUpdate(selectedStepChild, updatedContent);
  };

  return (
    <>
      {BLOCK_CATEGORIES[CONTENT].includes(blockCategory) && (
        <ContentSettings
          blockProperties={blockProperties}
          handleUpdate={handleUpdate}
        />
      )}
      {BLOCK_CATEGORIES[SURVEY].includes(blockCategory) && (
        <SurveySettings
          blockProperties={blockProperties}
          blockPropertiesWithVariations={blockPropertiesWithVariations}
          localeId={localeId}
          handleUpdate={handleUpdate}
        />
      )}
      <BlockScheme blockProperties={blockProperties} />
    </>
  );
}

BlockSettings.propTypes = {
  selectedStepChild: PropTypes.string,
  blockSettings: BlockShape,
  blockSettingsWithVariations: BlockShape,
  content: ContentShape,
  localeId: PropTypes.string,
  onUpdate: PropTypes.func,
};
const mapStateToProps = state => {
  const { id: localeId } = selectActiveLocale(state) ?? {};
  return {
    localeId,
  };
};
const mapDispatchToProps = {
  onUpdate: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockSettings);
